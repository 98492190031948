<template>
  <div class="SettingHome">
    <div class="userInfo">
      <div class="userHead" v-if="!userInfo.avatarUrl">
        <div class="userHead-icon">
          <svg-icon icon-class="bigUserHead" class="svg-icon"></svg-icon>
        </div>
        <!-- <div class="camera-icon">
          <svg-icon icon-class="camera" class="svg-icon"></svg-icon>
        </div> -->
      </div>
      <div class="userHead" v-if="userInfo.avatarUrl">
        <el-image class="image" :src="userInfo.avatarUrl"></el-image>
      </div>
      <div class="userInfo-bottom">
        <div class="user">
          <span v-if="userInfo.userType == 1"> {{ $t("lang.Admin") }}</span
          ><span v-if="userInfo.userType == 2"> {{ $t("lang.Staff") }}</span>
        </div>
        <div
          class="Inactivated"
          v-show="userInfo.emailFlag == 0 || userInfo.dataFlag == 0"
        >
          {{ $t("lang.SText1") }}
        </div>
      </div>
    </div>
    <div class="line1" v-show="userInfo && userInfo.userName">
      {{ $t("lang.SText2") }}, {{ userInfo.firstName }}
      {{ userInfo.secondName }}
    </div>
    <div class="line2 regula20">
      {{ $t("lang.SText3") }}
    </div>
    <div class="Log regula20">
      <div class="log-left">
        <div class="log-left-left">
          <div class="title">
            <span v-if="userInfo.lastOrCurrent == 1">
              {{ $t("lang.currentSignIn") }}</span
            ><span v-if="userInfo.lastOrCurrent == 2">
              {{ $t("lang.lastSignIn") }}</span
            >
          </div>
          <div class="">{{ userInfo.lastLoginDevice }}</div>
          <div class="time">{{ userInfo.lastLoginDate }}</div>
        </div>
        <div class="computer-icon">
          <svg-icon class="svg-icon icon1" icon-class="computer"></svg-icon>
        </div>
      </div>
      <div class="log-right">
        <div class="log-left-left">
          <div class="title">{{ $t("lang.SText4") }}</div>
          <div class="time">{{ userInfo.pwdUpdateDate }}</div>
          <div class="change" @click="ChangePassword">
            {{ $t("lang.ChangePassword") }}
          </div>
        </div>
        <div class="computer-icon">
          <svg-icon class="svg-icon icon2" icon-class="lock"></svg-icon>
        </div>
      </div>
    </div>
    <change-password
      v-if="changePsd"
      @changePsdFun="changePsdFun"
    ></change-password>
  </div>
</template>

<script>
import changePassword from "../../../components/changePassword/changePassword.vue";
export default {
  data() {
    return {
      changePsd: false,
      userInfo: {
        avatarUrl: "",
        userType: "",
        emailFlag: "",
        dataFlag: "",
        firstName: "",
        secondName: "",
      },
    };
  },
  components: {
    changePassword,
  },
  created() {},
  methods: {
    init() {
      this.$session("userInfo").get((value) => {
        if (!value) {
          let that = this;
          setTimeout(function () {
            that.init();
          }, 1000);
          return;
        }
        this.userInfo = value;
      });
    },
    ChangePassword() {
      // this.$router.push({ name: "Security", params: { changePassword: true } });
      this.changePsd = true;
    },
    changePsdFun() {
      this.changePsd = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.SettingHome {
  width: 900px;
  margin-left: 150px;
  .userInfo {
    width: 200px;
    margin: auto;

    .userHead {
      width: 200px;
      height: 200px;
      background-color: #f9f9f9;
      position: relative;
      .image {
        width: 200px;
        height: 200px;
      }
      .userHead-icon {
        .svg-icon {
          width: 130px;
          height: 146px;
          margin: 27px 0;
          fill: #8d8686;
          stroke: #8d8686;
        }
      }
      .camera-icon {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($color: #e6e6e6, $alpha: 0.5);
        height: 50px;
        .svg-icon {
          width: 24px;
          height: 22px;
          margin: 14px 0;
          color: #fff;
        }
      }
    }

    .userInfo-bottom {
      display: flex;
    }

    .user {
      height: 32px;
      min-width: 108px;
      line-height: 32px;
      text-align: center;
      background-color: #e6e6e6;
      color: #808080;
      font-size: 20px;
      margin-top: 20px;
      padding: 0 5px;
    }
    .Inactivated {
      height: 32px;
      width: 108px;
      line-height: 32px;
      text-align: center;
      color: $background3;
      font-size: 14px;
      margin-top: 20px;
      text-decoration: underline;
    }
  }

  .line1 {
    font-size: 60px;
    color: $mainColor;
    margin-top: 20px;
  }
  .line2 {
    margin-top: 5px;
    color: #b3b3b3;
    font-family: "montserratLight";
  }
  .Log {
    display: flex;
    justify-content: space-between;
    font-family: "montserratLight";
    .log-left,
    .log-right {
      border-top: 2px solid #e6e6e6;
      width: 385px;
      margin-top: 50px;
      display: flex;
      padding: 20px;
      justify-content: space-between;

      .log-left-left {
        text-align: left;
        color: $mainColor;
        .title {
          color: #b3b3b3;
          line-height: 50px;
        }

        .change {
          color: $background2;
          text-decoration: underline;
          margin-top: 40px;
          cursor: pointer;
        }
      }

      .computer-icon {
        .svg-icon {
          margin-top: 30px;
          fill: #b3b3b3;
          stroke: #b3b3b3;
        }

        .icon1 {
          height: 60px;
          width: 64px;
          color: #b3b3b3;
        }

        .icon2 {
          height: 64px;
          width: 64px;
          color: #b3b3b3;
        }
      }
    }
  }
}
</style>