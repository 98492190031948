<template>
    <div class="changePassword">
        <el-dialog
            :visible.sync="changePwdFlag"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="70%"
            @close="changePwdFlag = false">
            <div class="dialog-title1">
                <div class="left">
                    <svg-icon icon-class="security"></svg-icon>
                    {{ $t("lang.ChangePassword") }}
                </div>
                <div class="close" @click="changePsdFun()">
                    <svg-icon class="close-icon" icon-class="close"></svg-icon>
                </div>
            </div>
            <div class="dialogMain yangshistyle">
                <el-row class="uploadPage">
                    <el-form :inline="true" :model="changePwdForm" ref="changePwdForm" :rules="rules" class="search-form-inline changePwdForm" v-if="isshowForm">
                        <el-form-item :label="$t('lang.text1')" prop="oldPassword">
                            <el-input v-model="changePwdForm.oldPassword" placeholder="" :type="oldpassw" autocomplete="off">
                                <svg-icon :icon-class="elIcon" @click="flag = !flag" slot="suffix"></svg-icon>
                            </el-input>
                        </el-form-item>
                        <el-form-item :label="$t('lang.text2new')" prop="password">
                            <el-input v-model="changePwdForm.password" placeholder="" :type="passw" autocomplete="off">
                                <svg-icon :icon-class="elIcon1" @click="flag1 = !flag1" slot="suffix"></svg-icon>
                            </el-input>
                            <el-tooltip class="item" effect="dark" :content="$t('lang.text4')" placement="bottom" v-if="ruleFormpassword">
                                <i class="el-icon-warning"></i>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item :label="$t('lang.text3')" prop="rePassword">
                            <el-input v-model="changePwdForm.rePassword" placeholder="" :type="newpassw" autocomplete="off">
                                <svg-icon :icon-class="elIcon2" @click="flag2 = !flag2" slot="suffix"></svg-icon>
                            </el-input>
                            <el-tooltip class="item" effect="dark" :content="$t('lang.text4')" placement="bottom" v-if="ruleFormrePassword">
                                <i class="el-icon-warning"></i>
                            </el-tooltip>
                        </el-form-item>
                    </el-form>
                    <p class="xiugaicheng" v-if="isshowText">{{$t('lang.SText26')}}<br />{{$t('lang.SText27')}}</p>
                    <span class="daojishi" v-if="isshowText">{{$t('lang.SText28')}} {{count2}}{{$t('lang.SText29')}}</span>
                    <el-button class="signinPS" @click="tiSignin" v-if="isshowText">{{$t('lang.SText30')}}</el-button>
                    <div class="bottom-button" style="margin-top: 30px" v-if="isshowForm">
                        <el-button :loading="SavepwdLoading" type="primary" @click="Savepwd('changePwdForm')">{{$t('lang.SText13')}}
                        </el-button>
                        <el-button type="primary" style="background:transparent;color:#132569" @click="changePsdFun()">{{$t('lang.QText79')}}
                        </el-button>
                    </div>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import global from "../../utils/global";
export default {
    data() {
        const validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error(this.$t("lang.text8")));
            } else {
                if (this.changePwdForm.rePassword !== "") {
                    this.$refs.changePwdForm.validateField("rePassword");
                }
                callback();
            }
        };
        const validatePass3 = (rule, value, callback) => {
            if (value === "") {
                this.ruleFormpassword = true;
                callback(new Error(this.$t("lang.text8")));
            } else if (
                value.indexOf(this.changePwdForm.loginCode.split("@")[0]) != -1
            ) {
                this.ruleFormpassword = true;
                callback(new Error(this.$t("lang.text4")));
            } else {
                if (this.changePwdForm.rePassword !== "") {
                    this.$refs.changePwdForm.validateField("rePassword");
                }
                this.ruleFormpassword = false;
                callback();
            }
        };
        const validatePass2 = (rule, value, callback) => {
            if (value === "") {
                this.ruleFormrePassword = true;
                callback(new Error(this.$t("lang.text8")));
            } else if (value !== this.changePwdForm.password) {
                this.ruleFormrePassword = true;
                callback(new Error(this.$t("lang.text7")));
            } else if (
                value.indexOf(this.changePwdForm.loginCode.split("@")[0]) != -1
            ) {
                this.ruleFormrePassword = true;
                callback(new Error(this.$t("lang.text4")));
            } else {
                this.ruleFormrePassword = false;
                callback();
            }
        };
        return {
            changePwdFlag: true,
            ruleFormrePassword: false,
            ruleFormpassword: false,
            isshowText: false,
            isshowForm: true,
            flag: true,
            flag1: true,
            flag2: true,
            token: "",
            userinfo: "",
            timer2: null,
            count2: "",
            changePwdForm: {
                oldPassword: "",
                password: "",
                rePassword: "",
                loginCode: "",
            },
            rules: {
                oldPassword: [
                    { validator: validatePass, trigger: "blur" },
                    {
                        min: 8,
                        max: 18,
                        message: this.$t("lang.text4"),
                        trigger: "blur",
                    },
                ],
                password: [
                    { validator: validatePass3, trigger: "blur" },
                    {
                        min: 8,
                        max: 18,
                        message: this.$t("lang.text4"),
                        trigger: "blur",
                    },
                    {
                        trigger: "blur",
                        validator: (rule, value, callback) => {
                            // var passwordreg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/;
                            var passwordreg =
                                /^(?=.*[a-z]+)(?=.*?[A-Z])(?=.*[0-9]+)[a-zA-Z0-9]+$/;
                            console.log(passwordreg.test(value));
                            if (!passwordreg.test(value)) {
                                this.ruleFormpassword = true;
                                callback(new Error(this.$t("lang.text4")));
                            } else {
                                this.ruleFormpassword = false;
                                callback();
                            }
                        },
                    },
                ],
                rePassword: [
                    { validator: validatePass2, trigger: "blur" },
                    {
                        min: 8,
                        max: 18,
                        message: this.$t("lang.text4"),
                        trigger: "blur",
                    },
                    {
                        trigger: "blur",
                        validator: (rule, value, callback) => {
                            // var passwordreg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/;
                            var passwordreg =
                                /^(?=.*[a-z]+)(?=.*?[A-Z])(?=.*[0-9]+)[a-zA-Z0-9]+$/;
                            console.log(passwordreg.test(value));
                            if (!passwordreg.test(value)) {
                                this.ruleFormrePassword = true;
                                callback(new Error(this.$t("lang.text4")));
                            } else {
                                this.ruleFormrePassword = false;
                                callback();
                            }
                        },
                    },
                ],
            },
            SavepwdLoading: false,
        };
    },
    computed: {
        oldpassw() {
            return this.flag ? "password" : "text";
        },
        elIcon() {
            return this.flag ? "invisible" : "visible";
        },
        passw() {
            return this.flag1 ? "password" : "text";
        },
        elIcon1() {
            return this.flag1 ? "invisible" : "visible";
        },
        newpassw() {
            return this.flag2 ? "password" : "text";
        },
        elIcon2() {
            return this.flag2 ? "invisible" : "visible";
        },
    },
    created() {
        this.initData();
    },
    methods: {
        initData: function () {
            this.$session("userInfo").get((value) => {
                this.userinfo = value;
            });
            this.$session("token").get((value) => {
                this.token = value;
            });
            this.changePwdForm.loginCode = this.userinfo.loginCode;
        },
        tiSignin() {
          this.$router.replace('/login')
          // window.open(
            //     global.dewellUrl + "/" + "?st=001",
            //     "_self"
            // );
        },
        Savepwd: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.signUppwd();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        signUppwd() {
            let data = {
                password: this.changePwdForm.password,
                oldPassword: this.changePwdForm.oldPassword,
                __sid: this.token,
                loginCode: this.changePwdForm.loginCode,
            };
            this.SavepwdLoading = true;
            this.$ajax
                .cPost(this.source.account_api.changepwd, data)
                .then((res) => {
                    if (res.data.result == "true") {
                        // sessionStorage.setItem('user', '');
                        this.isshowForm = false;
                        this.isshowText = true;
                        this.getPhoneCode2();
                        this.changePwdFlag = true;
                        this.$notify({
                            title: res.data.message,
                            type: "success",
                            offset: 100,
                        });
                        sessionStorage.removeItem("@userInfo");
                        sessionStorage.removeItem("token");
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        localStorage.removeItem("loginInfo");

                        // this.sendFun(res.data.message, function () {
                        //   window.open(
                        //     global.dewellUrl + "/" + this.$i18n.locale + "/" + "?st=001",
                        //     "_self"
                        //   );
                        // });
                    } else {
                        this.isshowForm = true;
                        this.isshowText = false;
                        // this.getPhoneCode2()
                        // this.isshowForm=false
                        // this.isshowText=true
                        // this.sendFun({ m: res.data.message, t: "Auto hide" });
                        this.$notify({
                            title: res.data.message,
                            type: "error",
                            offset: 100,
                        });
                        this.changePwdFlag = true;
                    }
                    this.SavepwdLoading = false;
                });
        },
        getPhoneCode2() {
            let TIME_COUNT = 5;
            if (!this.timer2) {
                this.count2 = TIME_COUNT;
                this.show2 = false;
                this.timer2 = setInterval(() => {
                    if (this.count2 > 0 && this.count2 <= TIME_COUNT) {
                        this.count2--;
                        if (this.count2 == 0) {
                          this.$router.replace('/login')
                          // window.open(
                            //     global.dewellUrl +"/" +"?st=001","_self"
                            // );
                            clearInterval(this.timer2);
                        }
                    } else {
                        this.show2 = true;
                        clearInterval(this.timer2);
                        this.timer2 = null;
                    }
                }, 1000);
            }
        },
        changePsdFun() {
            this.changePwdFlag = false;
            this.$emit('changePsdFun')
        }
    },
    mounted() {},
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.changePassword {
    .el-dialog {
        margin-top: 0 !important;
        min-height: 100%;
        margin: 0 auto;
        .el-dialog__header {
            padding: 0;
            display: none;
        }
        .el-dialog__body {
            padding: 0;
            min-height: 100%;
            .yangshistyle {
                padding: 40px 50px;
                .el-input__suffix {
                    line-height: 60px;
                    font-size: 20px;
                    right: 15px;
                }
                .changePwdForm {
                    .el-form-item {
                        width: 60%;
                        label {
                            width: 80%;
                            font-family: "montserratregular";
                            color: #132569;
                            font-size: 27px;
                            padding: 0;
                        }
                        .el-form-item__content {
                            width: 80%;
                            text-align: left;
                            .el-input {
                                width: 90%;
                            }
                            a {
                                text-decoration: underline;
                                color: #299be4;
                                margin-top: 10px;
                                line-height: 60px;
                                margin-left: 3%;
                                text-decoration: underline;
                                font-size: 20px;
                                cursor: pointer;
                                font-family: "Montserrat";
                            }
                        }
                    }
                }
                .xiugaicheng {
                    font-family: "montserratregular";
                    color: #132569;
                    font-size: 40px;
                    line-height: 75px;
                    text-align: left;
                    width: 70%;
                    margin: 100px auto 0;
                    span {
                        color: #299be4;
                    }
                }
                .daojishi {
                    font-family: "montserratregular";
                    color: #b3b3b3;
                    font-size: 14px;
                    text-align: left;
                    width: 70%;
                    float: left;
                    margin-left: 15%;
                    margin-top: 20px;
                }
                .signinPS {
                    width: 220px;
                    float: left;
                    margin-left: 15%;
                    margin-top: 40px;
                    background: #eb4b4b;
                    line-height: 32px;
                    color: #fff;
                    font-size: 20px;
                }
            }
        }
    }
}
</style>
